
.no-resize{
  resize: none;
}

.code{
  background: #f1f1f1;
  border: solid 1px #dddddd;
  border-radius: 4px;
  padding: 6px;
}

.avatar-wrapper {
  img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
}

.img-round {
  max-width: 100%;
  border-radius: 50px;
  border: 1px solid $primary-gha;
}

.txt-caption {
  
}